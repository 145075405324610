// import trtc from 'trtc-js-sdk';
import trtc from '@/components/trtc-js-sdk';
import config from '@/config';

export default class TrtcMonitor {
    _instance = null;
    _joined = false;
    _joining = false
    statsInterval=null;
    bandwidth=null

    constructor(options,vueInstance) {
        this.vueInstance = vueInstance;
        //trtc.Logger.disableUploadLog();
        trtc.Logger.disableUploadLog();
        trtc.Logger.setLogLevel(trtc.Logger.LogLevel.WARN);
        this._instance = trtc.createClient({...options, enableAutoPlayDialog: false });
    }

    /**
     * 创建监控端
     * @param id
     * @param sig
     * @returns {TrtcMonitor}
     * @TrtcMonitor
     */
    static CreateMonitor(id, sig, sdkAppId,vueInstance) {
        return new TrtcMonitor({
            sdkAppId:  Number(sdkAppId) || Number(config.Trtc.SdkAppId),
            userId: id,
            userSig: sig,
            mode: 'rtc',
        },vueInstance);
    }

    /* 操作API Start */

    /**
     * 进入考场
     * @param hall
     * @returns {Promise<void>}
     * @constructor
     */
    async Join(hall) {
        try {
            if(this._joining) {return}
            this._joining = true
            await this._instance.join({
                roomId: hall,
            });
            this._joined = true;
        } catch (e) {
            throw Error(`进入房间失败: ${e}`);
        }
    }

    /**
     * 主动离开考场
     * @returns {Promise<void>}
     * @constructor
     */
    async Leave() {
        if(!this._instance) return
        try {
          if (this._joined) {
            this._joined = false
            await this._instance.leave().then(() => {
                if (this.statsInterval) {
                  clearInterval(this.statsInterval);
                  this.statsInterval = null; // 清除定时器ID
                }
            }).catch(error => {
                console.error('leaving room failed: ' + error);
                // this._instance.leave()
            });
        }
        } catch (error) {
            console.warn(`重复退出房间: ${error}`)
        }
    }

    async Destroy() {
        this._instance.destroy()
        this._joined = false
        this._instance = null
        console.log('destroy room success')
    }

    /**
     * 查看指定用户的流(订阅)
     * @param stream
     * @param options
     * @returns {Promise<void>}
     * @constructor
     */
    async Watch(stream, options) {
        if (this._joined) {
            this._instance.subscribe(stream, options);
        }
    }

    /**
     * 忽略指定用户的流(取消订阅)
     * @param stream
     * @returns {Promise<void>}
     * @constructor
     */
    async Overlook(stream) {
        if (this._joined) {
            this._instance.unsubscribe(stream);
            this._instance.off('*');
        }
    }
    /* 操作API End */

    /* 事件Hook列表 Start */

    /**
     * 当有用户进入房间前
     * @param delegate
     * @constructor
     */
    WhenClientJoin(delegate) {
        this._instance.on('stream-added', delegate);
    }

    /**
     * 当用户进入房间后
     * @param delegate
     * @constructor
     */
    AfterClientJoin(delegate) {
        // this._instance.on('stream-subscribed', delegate);
        this._instance.on('stream-subscribed', stream => {
          let lastBytesReceived = 0;
          let lastTime = Date.now();
          // 延迟几秒获取统计信息，等待数据更新
          this.statsInterval= setInterval(() => {
            const now = Date.now();
            const timeInterval = (now - lastTime) / 1000; // 秒
            this._instance&&this._instance.getRemoteVideoStats().then(videoStats => {
                  // console.log('远端视频统计:', videoStats);
                  // 假设 remoteUserId 是远端用户的 ID
                  Object.keys(videoStats).forEach(userId => {
                    const bytesReceivedNow = videoStats[userId].bytesReceived;
                    if (lastBytesReceived > 0) {
                      const bytesInterval = bytesReceivedNow - lastBytesReceived;
                      this.bandwidth = (bytesInterval * 8/1000000 ) / timeInterval * 1000; // bps
                      this.vueInstance.updateBandwidth(userId, this.bandwidth.toFixed(0));
                      // console.log(`${userId}当前带宽: ${this.bandwidth.toFixed(2)} Mbps`);
                    }
                    lastBytesReceived = bytesReceivedNow;
                    lastTime = now;
                });
              }).catch(error => {
                  console.error('获取远端视频统计信息失败:', error);
              });
          }, 2000); // 延迟3秒获取数据
  
          delegate(stream);
      });
    }

    /**
     * 当禁用视频时
     * @param delegate
     * @constructor
     */
    WhenMuteVideo(delegate) {
        this._instance.on('mute-video', delegate);
    }

    /**
     * 当取消禁用视频时
     * @param delegate
     * @constructor
     */
    WhenUnMuteVideo(delegate) {
        this._instance.on('unmute-video', delegate);
    }

    /**
     * 错误处理
     * @param delegate
     * @constructor
     */
    ReceivedError(delegate) {
        this._instance.on('error', delegate);
    }
    /* 事件Hook列表 End */
}

<template>
    <div style="height: 100%">
        <v-toolbar dense dark>
            <v-row class="text-left" no-gutters>
                <v-col v-if="title" class="title">
                    <span v-if="area">【{{ area }}】</span>
                    <span>{{ title }}</span>
                    <span>（共{{ count }}个视频）</span>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <slot name="options">
                <v-btn color="white" class="mr-2" outlined @click="prev"> 上一页</v-btn>
                <v-btn color="white" class="mr-2" outlined @click="next"> 下一页 </v-btn>
            </slot>
        </v-toolbar>
        <v-row no-gutters>
            <!-- 重大修改记录2021.12.28  -->
            <!-- 之前方案每次生成 item.length 个 video,翻页后重新生成，新方案固定9个，翻页只改变 url  -->
            <!-- 方案：默认显示渲染9个宫格 -->
            <!-- <div v-for="(item, index) in items" :key="index" id="equipments">
                <video-player
                    class="monitor-div"
                    :style="playerStyle"
                    :playerOptions="{
                        preload: false,
                        pause:true
                    }"
                    :url="GetPlayerUrl(item.equipmentNo)"
                    :vid="'tcplayer'+index"
                >
                    <template #title>
                        {{ item.examinationRoomCode }}
                        <span>{{ item.examinationRoomName }}</span>
                        <p class="float-right px-2">{{ nowDate }}</p>
                    </template>
</video-player>
</div> -->
            <!-- <div v-for="(item, i) in 9" :key="i" id="equipments" :index="i" class="video-item" :style="playerStyle"
                :class="i<items.length?'show':'hidden'">
                <video-player
                    ref='player'
                    class="monitor-div"
                    :playerstyle="player"
                    :url="GetPlayerUrl(i)"
                    :vid="'tcplayer'+i"
                    :enableFil="enableFil"
                >
                    <template #title>
                        <div class="RoomTitle">
                            <div class="RoomTitle-L">
                                <span class="s">{{ GetRoomCode(i) }}</span>
                                <span class="s">{{ GetRoomRoomName(i) }}</span>
                                <span class="s">{{ GetRoomPosition(i) }}</span>
                            </div>
                            <div class="RoomTitle-R">
                                {{ nowDate }}
                            </div>
                        </div>
                    </template>
                </video-player>
           </div> -->
            <div v-for="(item, i) in items" :key="item.userId" class="video-item" :style="playerStyle"  :enableFil="enableFil">
                <registry
                    :ref="item.userId"
                    :registry="{id: item.userId}"
                    :monitor="allMonitor[i].monitor"
                     :enableFil="enableFil"
                     :itemObj="item"
                    >
                    <template #info>
                        <div class="RoomInfo">
                            <div class="RoomTitle-R">
                                <!-- {{ nowDate }} -->
                                <span class="s">{{ GetRoomText(i) }}</span>
                            </div>
                        </div>
                    </template>
                    <!-- <template #info>
                        <div class="RoomInfo">

                                <span class="s">{{ GetRoomCode(i) }}</span>
                                <span class="s">{{ GetRoomRoomName(i) }}</span>
                                <span class="s">{{ GetRoomPosition(i) }}</span>

                        </div>
                    </template> -->
                </registry>
            </div>
        </v-row>
    </div>
</template>
<script>
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
/* eslint-disable */
import TrtcMonitor from '@/infrastructures/trtc/monitor';
// import VideoPlayer from '@/components/videoPlayer/index';
import registry from '@/components/trtc/registry';
import Timer from '@/infrastructures/timer/timer';


import { EventBus } from '@/utils/event-bus.js';
export default
@Component({
    components: {
        // VideoPlayer,
        registry
    },
})
class MonitorViewComponent extends Vue {
    @Prop() items;
    @Prop() title;
    @Prop() area;
    @Prop() width;
    @Prop() height;
    @Prop() count;
    @Prop() enableFil;

    @Emit()
    prev() { }

    @Emit()
    next() { }
    mainJoin = 0;
    bandwidthData={}
    get playerStyle() {
        return {
            width: `${this.player.width}px`,
            height: `${this.player.height}px`,
        };
    }

    player = {
        width: 640,
        height: 360,
    };
    allMonitor = [];
    nowDate = '';
    timer = Timer.CreateFromSecond(this.formatDate, 1);
    clientWidth = document.documentElement.clientWidth;
    clientHeight = document.documentElement.clientHeight;

    @Watch('items', { immediate: false, deep: true })
    async OnItemsChanged(val, oldVal) {
        let count = val.length,
            width = this.width ?? this.clientWidth,
            height = (this.height ?? this.clientHeight) - 48; //减去header高度
        this.Calc(count, width, height);
        if(val.length > 0 && this.allMonitor.length === 0) {
            console.log('items OnItemsChanged 2', val);
            // 初始化 monitor 客户端
            await this.handleInitMonitor();
        }
    }


    muted() {
        for (const item of this.items) {
            this.$refs[item.userId][0].MuteAudio()
        }
    }


    unmuted() {
        for (const item of this.items) {
            this.$refs[item.userId][0].unMuteAudio()
        }
    }

    mounted() {
        this.$nextTick(() => {
            this.timer.Start();
            this.OnItemsChanged(this.items);
        });
        EventBus.$on('resetMonitor',()=>{
            this.resetMonitor()
        })
        EventBus.$on('leaveRoom',()=>{
            this.beforeunloadFn()
        })
        window.addEventListener('beforeunload', this.beforeunloadFn);
    }

    resetMonitor() {
        if(this.allMonitor.length > 0) {
            this.allMonitor = []
            this.mainJoin = 0
        }
    }

    beforeunloadFn() {
        // this.allMonitor.map((v) => {
        //      v.monitor.Leave();
        //      console.log('退出房间')
        // });
        // this.allMonitor.map((v) => {
        //      v.monitor.Destroy();
        // });
        if (this.timer) {
            this.timer.Stop();
        }
    }

    beforeDestroy() {
        this.beforeunloadFn()
    }

    destroyed() {
        if(this.timer) {
            this.timer.Stop();
        }
        window.removeEventListener('beforeunload', this.beforeunloadFn);
    }

    async handleInitMonitor() {
        for(const element of this.items) {
            await this.handleInit(element);
        }
        await this.Join();
    }

    async handleInit(item) {
        let obj = {};
        obj.id = item.userId;
        let monitor = await TrtcMonitor.CreateMonitor(item.monitorId, item.monitorIdSig, item.sdkAppId,this);
        monitor.WhenClientJoin(async (event) => {
            await this.ClientPush(event, monitor);
        });
        monitor.AfterClientJoin(this.ClientJoined);
        obj.monitor = monitor;
        obj.roomId = Number(item.trtcRoomId);
        this.allMonitor.push(obj);
    }

    async Join() {
        this.$emit('updateStatus', false)
        this.mainJoin = 0
        this.allMonitor.forEach(async (v) => {
            let hall = v.roomId;
            if(hall) {
                await v.monitor.Join(hall).then(() => {
                    setTimeout(() => {
                        this.mainJoin += 1;
                        if(this.mainJoin === this.allMonitor.length){
                            this.$emit('updateStatus', true)
                        }
                    }, 1000);
                });
            }
        });
    }

    async ClientPush(event, monitor) {
        let stream = event.stream;
        await monitor.Watch(stream, {
            audio: true,
            video: true,
        });
    }

    async ClientJoined(event) {
        let stream = event.stream;
        let userId = stream.getUserId();
        this.$refs[userId][0]?.Bind(stream);
    }


    Calc(count, totalWidth, totalHeight) {
        let row = 1,
            col = 1;
        if(count <= 1) {
            row = 1;
            col = 1;
        } else if(count <= 2) {
            col = 2;
        } else if(count <= 4) {
            col = 2;
            row = 2;
        } else if(count <= 6) {
            col = 3;
            row = 2;
        } else if(count <= 9) {
            col = 3;
            row = 3;
        }

        this.player.width = totalWidth / col;
        this.player.height = totalHeight / row;
    }

    GetRoomText(i) {
        const room = this.items.length > i ? this.items[i] : null;
        if(room){
            return room.examinationSiteName + room.examinationRoomName + room.positionDesc;
        }
        return ''
    }

    GetRoomCode(i) {
        const roomCode = this.items.length > i ? this.items[i].examinationRoomCode : null;  // eslint-disable-line
        return roomCode;
    }

    GetRoomRoomName(i) {
        const roomName = this.items.length > i ? this.items[i].examinationRoomName : null;  // eslint-disable-line
        return roomName;
    }

    GetRoomPosition(i) {
        var positionDesc = this.items.length > i ? this.items[i].positionDesc : null;  // eslint-disable-line
        if(positionDesc && this.items[i].examinationRoomType == 1)
            positionDesc = '';
        return positionDesc;
    }

    GetPlayerUrl(i) {
        // const arr = [1,2,4,5,7,10,11,12,13,15,16,17,i]
        // const num = arr[Math.floor((Math.random()*arr.length))]
        const userId = this.items.length > i ? this.items[i].userId : null;  // eslint-disable-line
        // console.log('equipmentNo====>',equipmentNo, this.items)
        // return `http://cctvalih5ca.v.myalicdn.com/live/cctv${num}_2/index.m3u8`;
        return `${document.location.protocol}//player.live.zfoline.net/${userId}.m3u8`;
    }

    formatDate() {
        let date = new Date();
        let year = date.getFullYear(); // 年
        let month = date.getMonth() + 1; // 月
        let day = date.getDate(); // 日
        let week = date.getDay(); // 星期
        let weekArr = [
            '星期日',
            '星期一',
            '星期二',
            '星期三',
            '星期四',
            '星期五',
            '星期六',
        ];
        let hour = date.getHours(); // 时
        hour = hour < 10 ? '0' + hour : hour;
        let minute = date.getMinutes(); // 分
        minute = minute < 10 ? '0' + minute : minute;
        let second = date.getSeconds(); // 秒
        second = second < 10 ? '0' + second : second;
        let currentDate = date.getDate();
        day = currentDate < 10 ? ('0' + currentDate) : currentDate;
        this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    }
    updateBandwidth(id, bandwidth) {
      this.items.forEach(item=>{
        if(item.userId==id){
          this.$set(item, 'itemBandWidth', bandwidth);
        }
      })
      // console.log(this.items,id, bandwidth,1231);
    }

}
</script>
<style lang="scss" scoped>
.monitor-div {
    position: relative;
}

.no-gutters {
    display: flex;

    .video-item {
        width: 33.3%;
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .show {
        display: block;
    }

    .hidden {
        display: none;
        // position: absolute;
        // left: 0;
        // top: 0;
        // z-index: -1;
    }
}

</style>

<template>
    <div>
        <v-row no-gutters class="my-1">
            <v-col>
                <h3 class="text-center">{{ this.context.areaname }}（{{this.context.areacode}}）</h3>
            </v-col>
            <a
                v-if="isPublishUrlShow"
                class="blue--text"
                :href="publishUrl"
                target="_blank"
                style="position: absolute; right: 95px;">
                <v-btn class="primary-btn" color="success">
                    <v-icon size="20">mdi-link-variant</v-icon>
                    <span>考点管理员登录地址</span>
                </v-btn>
            </a>
            <v-col cols="auto">
                <v-btn
                style="position: absolute; right: 25px; top: 5px;"
                    color="primary"
                    :to="$to($views.ExamCsp.ExaminationDistrict.List,{examId:this.examId})"
                >
                    返回
                </v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-4">
            <!-- <div
                class="text-sm-left caption red lighten-5 pa-2"
                v-show="user.JurisdictionCheck"
            >
                温馨提示：1、下载考点考场信息导入模版
                <span class="text-sm-left caption lighten-5">
                    <a @click="downloadTemplate">【下载模版】</a>
                </span>
                2、导入考点考场信息
            </div> -->
            <v-row class="d-flex mb-n4 mt-3" no-gutters>
                 <!-- <v-col cols="auto">
                    <div class="d-flex">
                        <div
                            cols="auto"
                            class="mt-2"
                            v-show="user.JurisdictionCheck"
                        >
                            <label>导入考点信息</label>
                        </div>
                        <div
                            class="input-width"
                            v-show="user.JurisdictionCheck"
                        >
                            <v-file-input
                                :rules="[(v) => !!v || '请选择要导入Excel']"
                                outlined
                                dense
                                label="请选择Excel模板"
                                v-model="file"
                            />
                        </div>
                        <div class="ml-2" v-show="user.JurisdictionCheck">
                            <v-btn color="primary" @click="upload">导入</v-btn>
                        </div>
                    </div>
                </v-col> -->
                <v-col class="ml-3">
                    <div class="d-flex">
                        <div class="input-width mr-2">
                            <v-text-field
                                v-model="searchParam.code"
                                placeholder="请输入考点代码或名称查询"
                                dense
                                outlined
                            ></v-text-field>
                        </div>
                        <v-btn color="primary" @click="GetExaminationSites">
                            查询
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="ml-2"
                            v-show="user.JurisdictionCheck"
                            @click="addExaminationSiteClick"
                        >
                            新增考点
                        </v-btn>
                        <label class="mt-2 ml-3">显示全部监控</label>
                        <v-switch
                            color="success"
                            v-model="isShowMonitor"
                            small
                            outlined
                            hide-details
                            class="mr-2 mt-1 ml-2"
                            @change="onAllChange()"
                        ></v-switch>
                    </div>
                </v-col>
            </v-row>

            <v-data-table
                :headers="[
                    {
                        text: '考点代码',
                        value: 'code',
                        width: '100px',
                    },
                    {
                        text: '考点名称',
                        value: 'name',
                        width: '100px',
                    },
                    {
                        text: '显示监控',
                        value: 'isShowMonitor',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items="examinationSites"
                no-data-text="暂无数据"
            >

                <template #item.isShowMonitor="{ item }">
                        <v-switch
                            v-model="item.isShowMonitor"
                            color="success"
                            small
                            outlined
                            hide-details
                            class="mr-2 mt-0"
                            @change="onEnableChange(item)"
                        ></v-switch>
                </template>
                <template v-slot:item.options="{ item }">
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="lookMonitor(item)"
                    >
                        查看监控
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        :to="
                            $to($views.ExamCsp.ExaminationRoom.List, {
                                code: item.code,
                                name: item.name,
                                examId:item.name,
                                ...context,
                            })
                        "
                    >
                        考场管理
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        v-show="user.JurisdictionCheck"
                        class="mr-2"
                        @click="UpdateClick(item)"
                    >
                        修改
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        v-show="user.JurisdictionCheck"
                        outlined
                        class="mr-2"
                        @click="deleteClick(item.id)"
                    >
                        删除
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog max-width="500" persistent v-model="addExaminationSiteDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    {{ this.filedName }}
                </v-card-title>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考点代码：</label>
                    <v-text-field
                        v-model="addExaminationSite.code"
                        placeholder="请输入考点代码"
                        dense
                        required
                        outlined
                    />
                </div>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考点名称：</label>
                    <v-text-field
                        v-model="addExaminationSite.name"
                        placeholder="请输入考点名称"
                        dense
                        required
                        outlined
                    />
                </div>
                <v-divider></v-divider>
                <div class="text-center py-2">
                    <v-btn e2e-save-city @click="save()" color="primary">
                        保存
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="ml-2"
                        outlined
                        @click="addExaminationSiteDialog = false"
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            max-width="900"
            style="max-height: 500px; top: 20px"
            persistent
            v-model="errorDialog"
        >
            <v-card>
                <v-card-title>导入异常数据列表</v-card-title>
                <v-data-table
                    :headers="[
                        {
                            text: '考区代码',
                            value: 'areaCode',
                            width: '100px',
                        },
                        {
                            text: '考区名称',
                            value: 'areaName',
                            width: '100px',
                        },
                        {
                            text: '考点代码',
                            value: 'siteCode',
                            width: '100px',
                        },
                        {
                            text: '考点名称',
                            value: 'siteName',
                            width: '100px',
                        },
                        {
                            text: '考场代码',
                            value: 'roomCode',
                            width: '100px',
                        },
                        {
                            text: '考场名称',
                            value: 'roomName',
                            width: '100px',
                        },
                        {
                            text: '考场类型',
                            value: 'roomType',
                            width: '100px',
                        },
                        {
                            text: '错误信息',
                            value: 'errMsg',
                            width: '100px',
                        },
                    ]"
                    :items="errorInfos"
                    no-data-text="暂无数据"
                    :items-per-page="20"
                    hide-default-footer
                >
                    <template v-slot:item.errMsg="{ item }">
                        <span class="red--text">{{ item.errMsg }}</span>
                    </template>
                </v-data-table>
                <div class="text-center pa-2">
                    <v-btn color="primary" @click="errorDialog = false">
                        关闭
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ExcelGenerator from '@/infrastructures/excel/Generator';
import Reader from '@/infrastructures/excel/Reader';
import XLSX from 'xlsx/xlsx';
export default {
    name: window.$views.ExamCsp.ExaminationSite.List,
    inject: ['repository', 'navigation', 'notifier', 'user','enums'],
    data() {
        return {
            isPublishUrlShow:true,
            publishUrl: '',
            isShowMonitor:false,
            examId:'',
            errorInfos: [],
            errorDialog: false,
            file: null,
            headers: [
                '考区代码',
                '考区名称',
                '考点代码',
                '考点名称',
                '考场代码',
                '考场名称',
                '考场类型'
            ],
            areacode: '',
            examinationSites:  [],
            searchParam: { code: '', areacode: '' },
            addExaminationSite: { id: '', code: '', name: '' ,exam:''},
            addExaminationSiteDialog: false,
            filedName: '新增考点',
        };
    },
    computed: {
        type() {
            return this.context.type;
        },
        distrcitcode() {
            return this.context.areacode;
        },
    },
    mounted() {
        this.examId = this.context.examId;
        this.areacode = this.context.areacode;
        let loginInfo = this.user.GetInfo;
        if(loginInfo.type != this.enums.UserType.组考机构管理员.value) this.isPublishUrlShow = false;
        else {
            this.publishUrl = this.navigation.resolveUrl(
                this.$views.Login,
                {
                    exam: this.examId,
                    userType:this.enums.UserType.考点管理员.value
                }
            );
        }
        this.GetExaminationSites();
    },
    methods: {
        downloadTemplate() {
            let generator = new ExcelGenerator();
            let res = [
                {
                    考区代码: '14401',
                    考区名称: '广州市',
                    考点代码: '14',
                    考点名称: '广州市技师学院(黄石校区)',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场内'
                },
                {
                    考区代码: '14401',
                    考区名称: '广州市',
                    考点代码: '14',
                    考点名称: '广州市技师学院(黄石校区)',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场内'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '01',
                    考点名称: '河源技师学院',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场内'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '01',
                    考点名称: '河源技师学院',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '02',
                    考点名称: '河源市卫生学校',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '02',
                    考点名称: '河源市卫生学校',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14403',
                    考区名称: '惠州市',
                    考点代码: '01',
                    考点名称: '惠州经济职业技术学院',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14403',
                    考区名称: '惠州市',
                    考点代码: '01',
                    考点名称: '惠州经济职业技术学院',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场外'
                },
            ];
            let data = this.formatJson(this.headers, res);
            console.log(data);
            generator
                .AddHeaders(this.headers)
                .AddRows(data)
                .Save('考点考场导入信息模板.xlsx');
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },
        lookMonitor(item) {
            this.navigation.redirect(
                this.$views.Monitor.ExaminationSiteMonitor.Index,
                {
                    examId:item.examId,
                    code: item.code,
                    areacode: this.context.areacode,
                    type: this.context.type,
                    name: item.name
                }
            );
        },
        GetExaminationSites() {
            let roomType = localStorage.getItem('roomType');
            this.searchParam.areacode = this.areacode;
            this.searchParam.examId = this.examId;
            this.searchParam.type = this.type;
            this.searchParam.roomType = roomType;
            this.repository.ExaminationSite.GetExaminationSites(
                this.searchParam
            ).then((res) => {
                this.examinationSites = res.data;
            });
        },

        async loadingData() {
            let reader = new Reader();
            //读取excel处理
            let workbook = await reader.ReadWorkbook(this.file);
            console.log(workbook);
            console.log(this.file);
            let examinationSitesAndRooms =
                await this.GetExaminationSitesAndRooms(workbook);
            return examinationSitesAndRooms;
        },

        async GetExaminationSitesAndRooms(workbook) {
            let examinationSitesAndRooms = new Array();
            let sheetNames = workbook.SheetNames; // 工作表名称集合
            sheetNames.forEach((name) => {
                let worksheet = workbook.Sheets[name]; // 只能通过工作表名称来获取指定工作表
                let excelJson = XLSX.utils.sheet_to_json(worksheet);
                for (let excelmodel in excelJson) {
                    let examinationSitesAndRoom = new Object();
                    examinationSitesAndRoom.AreaCode =
                        excelJson[excelmodel].考区代码.toString();
                    examinationSitesAndRoom.SiteCode =
                        excelJson[excelmodel].考点代码.toString();
                    examinationSitesAndRoom.SiteName =
                        excelJson[excelmodel].考点名称.toString();
                    examinationSitesAndRoom.RoomCode =
                        excelJson[excelmodel].考场代码.toString();
                    examinationSitesAndRoom.RoomName =
                        excelJson[excelmodel].考场名称.toString();
                    examinationSitesAndRoom.AreaName =
                        excelJson[excelmodel].考区名称.toString();
                    examinationSitesAndRoom.RoomType =
                        excelJson[excelmodel].考场类型;
                    examinationSitesAndRooms.push(examinationSitesAndRoom);
                }
            });
            return examinationSitesAndRooms;
        },

        async upload() {
            if (this.file == undefined) {
                this.notifier.warn('请选择上传文件');
                return;
            }
            let fillExtension = this.file.name
                .substring(this.file.name.lastIndexOf('.') + 1)
                .toLowerCase();

            if (fillExtension.indexOf('xls') == -1) {
                this.notifier.warn('导入失败，请检查模板是否正确');
                return;
            }
            let data = await this.loadingData();
            if (data[0].AreaCode == undefined) {
                this.notifier.warn('导入失败，请检查模板是否正确');
                return;
            }
            if (data == '') {
                this.notifier.warn('Excel无导入数据，请填写！');
                return;
            }
            // if (data[0].AreaCode != this.areacode) {
            //     this.notifier.warn(
            //         '导入的考区代码与当前' +
            //             this.context.areaname +
            //             '考区代码不一致'
            //     );
            //     return;
            // }
            this.repository.ExaminationSite.ImportExamSiteAndRoom(data,this.examId)
                .then((res) => {
                    this.file = undefined;
                    if (res.data.length > 0 && res.data.length < data.length) {
                        this.errorDialog = true;
                        this.errorInfos = res.data;
                        this.notifier.warn('部分导入成功');
                        this.GetExaminationSites();
                    } else if (res.data.length == data.length) {
                        this.errorInfos = res.data;
                        this.errorDialog = true;
                        this.notifier.error('导入失败');
                    } else {
                        this.notifier.success('导入成功');
                        this.GetExaminationSites();
                    }
                })
                .catch((res) => {
                    console.log(res);
                    this.notifier.error('导入失败请检查格式是否正确');
                });
        },

        save() {
            this.AddOrUpdate();
        },

        addExaminationSiteClick() {
            this.addExaminationSiteDialog = true;
            this.filedName = '新增考点';
            this.isAdd = true;
            this.addExaminationSite = {};
            this.addExaminationSite.code = this.context.areacode
            this.addExaminationSite.examId =this.examId;
        },

        UpdateClick(item) {
            this.filedName = '修改考点';
            this.addExaminationSiteDialog = true;
            this.addExaminationSite.id = item.id;
            this.addExaminationSite.name = item.name;
            this.addExaminationSite.code = item.code;
        },

        async AddOrUpdate() {
            let result =
                await this.repository.ExaminationDistrict.GetExistExaminationArea(
                    this.addExaminationSite.code,this.examId
                );
            if (!result.data) {
                this.notifier.warn(
                    '输入的考点代码不正确（当前是在' +
                        this.distrcitcode +
                        '的考区下，故只能新增' +
                        this.distrcitcode +
                        'xxx这类型的考点代码）'
                );
                return;
            }
            this.repository.ExaminationSite.AddOrUpdate(
                this.addExaminationSite
            ).then(() => {
                this.addExaminationSiteDialog = false;
                this.notifier.success('保存成功');
                this.GetExaminationSites();
            });
        },

        deleteClick(id) {
            let roomType = localStorage.getItem('roomType');
            this.$confirm('提示', `确定要删除吗？`).then(() => {
                this.repository.ExaminationSite.Delete({
                    id: id,
                    roomType: roomType,
                }).then(() => {
                    this.notifier.success('删除成功');
                    this.GetExaminationSites();
                });
            });
        },

        /**
         * 切换监控显示按钮
         */
        async onEnableChange(examinationSite)
        {
            let data = {id : examinationSite.id};
            try {
                await this.repository.ExaminationSite.ChangeMonitorShow(data);
            } catch {
                this.notifier.error(
                    `操作考点[${examinationSite.code}]失败.`
                );
            }
        },

        /**
         * 批量切换
         */
        async onAllChange()
        {
            var data =
            {
                IsShowMonitor:this.isShowMonitor,
                AreaCode:this.areacode,
                ExamId:this.examId
            }
            await this.repository.ExaminationSite.ChangeAreaMonitorShow(data);
            this.GetExaminationSites();
        }
    },
};
</script>
<style lang="scss" scoped>
.my-1 {
    height: 45px;
    line-height: 45px;
    position: relative;
}
</style>

<!--
 * @Descripttion : 市级监控页
 * @Author       : pishengjun
 * @Date         : 2021-09-24 10:03:16
 * @LastEditors  : pishengjun
 * @LastEditTime : 2021-11-26 18:23:38
 * @FilePath     : \ExamMonitorSystem.ServicePlatform.UI\src\views\Monitor\ExaminationCityMonitor\Index.vue
-->

<template>
    <div class="full">
        <monitor-view
            :title-handler="GetExaminationSites"
            :page-handler="GetOnlineEquipmentCount"
            :page-data-handler="GetOnlineEquipments"
        >
            <template #options>
                <v-btn @click="back" outlined dark>返回</v-btn>
            </template>
        </monitor-view>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView2/Extend';
import Storage from '@/domain/storage';
import keys from '@/domain/keys';
import { EventBus } from '@/utils/event-bus.js';

export default
@Component({
    components: { MonitorView },
})
class ExaminationRoomMonitor extends Vue {
    static name = window.$views.Monitor.ExaminationCityMonitor.Index;
    @Inject()
    notifier;

    @Inject()
    navigation;

    @Inject()
    repository;
    siteList = {};
    // currentCode = ''
    get code() {
        return this.context.code;
    }

    get examId()
    {
        return this.context.examId;
    }

    created() {
        if (
            Storage.Get(keys.REGISTRY_INFO) != undefined &&
            Storage.Get(keys.REGISTRY_INFO) != null
        ) {
            this.siteList.type = JSON.parse(
                Storage.Get(keys.REGISTRY_INFO)
            ).type;
        }
    }
    back() {
        this.$router.go(-1);
    }

    // 返回当前考点的推流信息  [{video: ''},{}]
    async GetOnlineEquipments(page) {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineMonitorEquipments({
                // groupNo: this.code || page.code,
                // groupNo: page.code || this.currentCode || '',
                groupNo: page.code || this.code || '',
                pageSize: page.size,
                page: page.cur,
                roomType: roomType,
                examId:this.examId
            });
        return response.data;
    }

    // 返回当前考点的在线数量 number
    async GetOnlineEquipmentCount(page) {
        EventBus.$emit('resetMonitor')
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineEquipmentCount({
                // groupNo: page.code || this.currentCode || '',
                groupNo: page.code || '',
                roomType: roomType,
                examId:this.examId
            });
        return response.data || 0;
    }

    // 返回考区下所有的考点信息  tree
    async GetExaminationSites(params) {
        let roomType = localStorage.getItem('roomType');
        if (params) {
            params.roomType = roomType;
            params.examId = this.examId
        }
        const param = params
            ? params
            : {
                  code: '',
                  areacode: this.code || '',
                  type: 1,
                  roomType: roomType,
                  examId:this.examId
              };
        let response =
            await this.repository.ExaminationSite.GetExaminationSites(param);
        // this.currentCode = response.data[0].code
        return response.data.filter(v=> v.isShowMonitor === true);
    }
}
</script>
